import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import {
  DropDown,
  Grid,
  LeftCol,
  DropDownTitle,
  DropDownList,
  DropDownListItem,
  RightCol,
  BtnTool,
  ImgWrapper,
  InfoContent
} from './dropdown'
import PrimaryLinkButton from '../../Button/PrimaryLinkButton'
import ArrowNextIcon from '../../Icons/ArrowNextIcon'
import InfoIcon from '../../Icons/InfoIcon'



function MetalCarportsDropdown({ isActive }) {
  const [activeTab, setactiveTab] = useState(0);
  function Handletab(key) {
    setactiveTab(key)
  }
  return (
    <DropDown className={`dropdown-menu ${isActive ? 'active' : ''}`}>
      <Grid>
        <LeftCol>
          {/* <BtnTool>
            <Link to="/metal-carports"><PrimaryLinkButton text="Explore All Metal Carports" icon={<ArrowNextIcon />} /></Link>
          </BtnTool> */}
          <DropDownList>
            {[
                {
                title: 'Explore All Metal Carports',
                link: '/metal-carports',
                InfoContent:`The modern metal carport is a sleek, customized structure, with a cold-formed steel frame. At Liberty Metal Structures, we pride ourselves on delivering the most durable and stylish carports in the industry! We offer beautiful A-frames and budget-friendly roofs; you can choose carports with enclosed walls or wide-open workspaces. Whatever your heart desires, the custom options available with our metal carports will accommodate you.`  
                },
                {
                title: 'A-Frame Vertical Roof Carports',
                link: '/a-frame-vertical-roof-carports',
                InfoContent:`A-Frame vertical roof carports are among some of the most steadfast buildings available. They’re durable, long-lasting, and can be installed in a fraction of the time it takes to construct similar stick-built structures. And they’re versatile! No matter what design or style you need, our metal buildings are a fantastic solution!`  
                },
                {
                title: 'A-Frame Horizontal Roof Carports',
                link: '/a-frame-horizontal-roof-carports',
                InfoContent:`A-Frame horizontal roof carports are a great, long-lasting building option that works well for many different applications. They’re also durable,  easy to maintain, and can be built in a matter of days. This makes them one of the most cost-effective construction methods available, allowing you to save money before, during, and for years after your building has been installed.` 
                },
                {
                title: 'Regular Roof Carports',
                link: '/regular-roof-carports',
                InfoContent:`Regular roof carports are among the most economical metal building choices available today. Durable, long-lasting, and capable of being installed far quicker than similarly sized wooden construction projects, it’s no surprise that our metal carports are so popular!` 
                }].map((item, index) => (
                  <DropDownListItem key={index} onMouseOver={() => Handletab(index)}>
                      <Link to={item.link}>{item.title}</Link>
                      <div className="mobile-view dropdown-item-inner">                       
                        <span className="info-icon">
                          <InfoIcon />
                          <InfoContent className="info-content">{item.InfoContent}</InfoContent>
                        </span>
                      </div>
                    </DropDownListItem>
                ))}
          </DropDownList>
          
        </LeftCol>
        {[
            {
              title: 'Explore All Metal Carports',
              link: '/metal-carports',
              InfoContent:`The modern metal carport is a sleek, customized structure, with a cold-formed steel frame. At Liberty Metal Structures, we pride ourselves on delivering the most durable and stylish carports in the industry! We offer beautiful A-frames and budget-friendly roofs; you can choose carports with enclosed walls or wide-open workspaces. Whatever your heart desires, the custom options available with our metal carports will accommodate you.`  
            },
            {
            title: 'A-Frame Vertical Roof Carports',
            link: '/a-frame-vertical-roof-carports',
            InfoContent:`A-Frame vertical roof carports are among some of the most steadfast buildings available. They’re durable, long-lasting, and can be installed in a fraction of the time it takes to construct similar stick-built structures. And they’re versatile! No matter what design or style you need, our metal buildings are a fantastic solution!`  
            },
            {
            title: 'A-Frame Horizontal Roof Carports',
            link: '/a-frame-horizontal-roof-carports',
            InfoContent:`A-Frame horizontal roof carports are a great, long-lasting building option that works well for many different applications. They’re also durable,  easy to maintain, and can be built in a matter of days. This makes them one of the most cost-effective construction methods available, allowing you to save money before, during, and for years after your building has been installed.` 
            },
            {
            title: 'Regular Roof Carports',
            link: '/regular-roof-carports',
            InfoContent:`Regular roof carports are among the most economical metal building choices available today. Durable, long-lasting, and capable of being installed far quicker than similarly sized wooden construction projects, it’s no surprise that our metal carports are so popular!` 
            }].map((item, index) => (
            activeTab === index && 
            <RightCol className="desktop-view" key={index}>
              <DropDownTitle className='h3'>{item.title}</DropDownTitle>
              <p>{item.InfoContent}</p>
              <ImgWrapper>
                {index === 0 &&
                  <StaticImage src="../../../images/menu/metal-carports.jpg"
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="metal-carports" />
                }
                {index === 1 &&
                  <StaticImage src="../../../images/menu/vertical-carport.jpg"
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="vertical-carport" />
                }
                {index === 2 &&
                  <StaticImage src="../../../images/menu/a-frame-carport.jpg"
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="a-frame-carport" />
                }
                {index === 3 &&
                  <StaticImage src="../../../images/menu/regular-carport.jpg"
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="regular-carport" />
                }
              </ImgWrapper>
              <BtnTool>
                <Link to={item.link}><PrimaryLinkButton text={item.title} icon={<ArrowNextIcon />} /></Link>
              </BtnTool>
            </RightCol>
          ))}
      </Grid>
    </DropDown>
  )
}

export default MetalCarportsDropdown
