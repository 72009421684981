import React from "react"
import { Link } from 'gatsby'
import {
  DropDown,
  DropDownList,
  DropDownListItem,
} from './dropdown'


function ResourcesDropdown({ isActive }) {
  return (
    <DropDown className={`dropdown-menu ${isActive ? 'active' : ''}`}>
        <DropDownList>
        {
            [
            {
                title: 'Rent-To-Own',
                link: '/rent-to-own',
            },
            {
                title: 'Financing',
                link: '/financing',
            },
            {
                title: 'Service Area',
                link: '/service-area',
            },
            {
                title: 'Color Options',
                link: '/color-options',
            },
            {
                title: 'FAQs',
                link: '/faq',
            },
            {
                title: 'Steel Building Components',
                link: '/steel-building-components',
            },
            {
                title: 'Metal Building Roof Guide',
                link: '/metal-building-roof-guide',
            },
            {
                title: 'Site Preparation Guide',
                link: '/site-preparation-guide',
            },
            {
                title: 'Order/Installation Process',
                link: '/order-installation-process',
            },
            {
                title: 'Blog',
                link: '/blog',

            }].map((item) => (
                <DropDownListItem key={item}>
                    <Link to={item.link}>{item.title}</Link>
                </DropDownListItem>
            ))}
        </DropDownList>
    </DropDown>
  )
}

export default ResourcesDropdown