import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const PrimaryLinkBtn = styled(BaseButton)`		
    color:#033366;
    padding:0;
	& .icon{
		> svg{
			fill:#033366;
		}
	}
	&:hover{
        color:#000;
        & .icon{
            > svg{
                fill:#000;
            }
	    }
	}
`

const PrimaryLinkButton = (props) => {
	const { icon, text, size } = props;
	return(
		<PrimaryLinkBtn className={`btn ${size ? `btn-${size}` : ''} `}>
            <>
                { text &&
                    <span className='text'>{text}</span>
                }
                { icon &&
                    <span className='icon'>{icon}</span>
                }
            </>

		</PrimaryLinkBtn>
	)
}

export default PrimaryLinkButton