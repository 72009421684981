import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const SocialIcon = styled.ul`
  display: flex;
  justify-content: space-between;
  margin:0 -6px;
  padding: 0;  
`
const SocialItem = styled.li`
  list-style: none;    
  padding:0 6px;
  margin-bottom:0;
  a{
    width: 38px;
    height: 38px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const SocialNetworkIcon = () => {
	return(	
    <SocialIcon>
      <SocialItem>
        <Link className="item-link" to="/">
          <StaticImage            
            src="../../svg/facebookicon.svg"
            alt="A Gatsby astronaut"
          />
        </Link>
      </SocialItem>
      <SocialItem>
        <Link className="item-link" to="/">
          <StaticImage
            src="../../svg/instagramicon.svg"           
            alt="A Gatsby astronaut"
          />
        </Link>
      </SocialItem>
      <SocialItem>
        <Link className="item-link" to="/">
          <StaticImage
            src="../../svg/youtubeicon.svg"
            alt="A Gatsby astronaut"
          />
        </Link>
      </SocialItem>
    </SocialIcon> 			
	)
}

export default SocialNetworkIcon