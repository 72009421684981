import React, {useState, useEffect} from "react"
import styled from "styled-components" 
import { Link, useStaticQuery, graphql } from "gatsby"
import axios from 'axios'
import { navigate } from "gatsby"

import { Container } from "../Section"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"
import SocialNetwork from "../SocialNetwork"
import Address from "../Address"
import PrimaryButton from "../Button/PrimaryButton"
import PaperplaneIcon from "../Icons/PaperplaneIcon"
import PhoneIcon from "../Icons/PhoneIcon"

const FooterWrap = styled.footer`  
  overflow:hidden;
  position:relative; 
`
const FooterInner = styled.footer`  
  background-color:#fff;
  box-shadow:0 25px 50px rgba(3,51,102,0.2);
  padding:0 4.166%;
  ${BreakpointUp.xxl`    
    padding:0 8.333%;
  `}
`
const FooterTop = styled.div`  
  border-bottom:2px dashed rgb(3 51 102 / 30%);
  padding:20px 0;
  ${BreakpointUp.xl`
    padding:30px 0;
  `}
  ${BreakpointUp.xxl`
    padding:40px 0;
  `}
`
const FooterMiddle = styled.div`
  border-bottom:2px dashed rgb(3 51 102 / 30%);
  padding:20px 0;
  ${BreakpointUp.xl`
    padding:30px 0;
  `}
  ${BreakpointUp.xxl`
    padding:40px 0;
  `}
`
const FooterBottom = styled.div`
  padding:20px 0 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction:column;
  ${BreakpointUp.sm`
    flex-direction:row;
  `}
  ${BreakpointUp.md`
    padding:20px 0;
  `}
  
`


const FooterGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  position:relative;
  z-index:1;
`
const FooterGridLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;  
  ${BreakpointUp.xl`    
    flex: 0 0 25%;
    max-width: 25%;
  `}
  strong{
    display:block;
    font-size:18px;
    line-height:28px;
    font-weight:700;
    color:#000;
    margin:0 0 10px;
    a{
      color:#D51333;
      text-decoration:underline;
      &:hover{
        text-decoration:none;
      }
    }
  }
`
const FooterGridRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.xl`    
    flex: 0 0 75%;
    max-width: 75%;
  `}
`
const MenuGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin:0 -15px;
  justify-content:space-between;
`
const MenuItemGrid = styled.div`
  position: relative;
  ${BreakpointDown.lg`
   width: 100%;
  `}
  padding:0 15px;
  @media(min-width:768px) and (max-width:991px){
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
`
const MenuTitle = styled.div`
  color:#000;  
  font-weight:700;
  ${BreakpointUp.md` 
    margin-bottom:10px;  
  `}
`

const MenuList = styled.ul`
  list-style:none;
  padding:0;
  margin:0 0 30px;
  ${BreakpointUp.md` 
    margin:0;
  `}
  ${BreakpointDown.md` 
    columns: 2;
    margin:0 0 10px;
  `}
  ${BreakpointDown.sm` 
    columns: 1;
  `}
`
const MenuItem = styled.li`
  margin-bottom:0;  
  position:relative;
  font-size:14px;
  line-height:28px;
  ${BreakpointUp.xxl`
    font-size:16px;
    line-height:38px;
  `}
  & a {
    color:#666;
    display:inline-block;
    text-decoration:none;
    &:hover {
      color:#000;
    }
  }

`

const Copyright = styled.div`
  font-size:14px;
  line-height:24px;
`

const NewsletterWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-right: -15px;
  margin-left: -15px;
`
const NewsletterTitle = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  font-weight: 700;
  color: #033366;
  padding:0 15px;
  margin: 10px 0;
  font-family: "Barlow", sans-serif;
  font-size: 20px;
  line-height: 28px;
  ${BreakpointUp.md`
    flex: 0 0 50%;
    max-width: 50%;
    font-size: 24px;
    line-height: 30px;
  `}
  ${BreakpointUp.lg`
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    font-size: 28px;
    line-height: 36px;
  `}
  ${BreakpointUp.xxl`
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    font-size: 32px;
    line-height: 42px;
  `}
`
const NewsletterForm = styled.form`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding:0 15px;
  margin: 10px 0;
  ${BreakpointUp.md`
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointUp.lg`
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  `}
  ${BreakpointUp.xxl`
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  `}
`
const FooterCta = styled.div`
  background-color: #fff;
  box-shadow: 0px -5px 25px 0px rgb(0 75 155 / 25%);
  width:100%;
  position: fixed;
  bottom: 0;
  left:0;
  right: 0;
  z-index: 20;
  text-align: center;
  display: block;  
  padding: 15px;
  ${BreakpointUp.md`
    display: none;  
  `}
  .btn{
    width: 100%;
  }
`
// const LocationTitle = styled.div`
//   color:#000;  
//   font-weight:700;
//   margin-bottom:10px;  
  
// `
// const LocationList = styled.div`
//   font-size: 14px;
//   line-height: 28px;
//   position: relative;
//   box-sizing: border-box;
//   column-gap: 5px;
//   column-count: 2;
//   ${BreakpointUp.sm`
//     column-count: 3;
//   `}
//   ${BreakpointUp.md`
//     column-count: 4;
//   `}
//   ${BreakpointUp.xl`
//     column-count: 5;
//   `}
//   ${BreakpointUp.xxl`
//     font-size:16px;
//     line-height:38px;
//  `}
// `
// const LocationItem = styled.div`
//   a{
//     position: relative;
//     color:#666;
//     &:hover,&:focus{
//       color:#000;  
//     }
//   }
// `
const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulState {
        edges {
          node {
            name
            url
          }
        }
      }
    }
  `)
  const [email, setEmail] = useState("")
  const handleSubmit = (e) => {
    e.preventDefault()
    if (email != "") {
      const data = {
        form: e.target.id,
        email: email,
        api_token: process.env.GATSBY_FORM_CRM_TOKEN,
      }

      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN },
      })
        .then(res => {
          setEmail("")
          navigate("/thank-you-for-subscribing/")
        })
        .catch(error => {
          alert(error)
        })
    }
  }
  return(
  <FooterWrap>  
      <Container maxWidth="100%" pl="3%" pr="3%">
        <FooterInner>
          <FooterTop>
            <NewsletterWrap>
              <NewsletterTitle>Subscribe to exclusive offers & update in your inbox every week!</NewsletterTitle>
              <NewsletterForm onSubmit={handleSubmit} id="subscribe">
                <div className="input-icon input-signup right">
                  <input
                    type="search"
                    placeholder="Enter your Email Address…"
                    className="form-control"
                    type="email"
                    onChange={(e)=>setEmail(e.target.value)}
                  />
                  <button type="submit" aria-label="submit" className="icon">
                    <PrimaryButton textAfter="Subscribe" icon={<PaperplaneIcon />} size="lg" />
                  </button>
                </div>
              </NewsletterForm>
            </NewsletterWrap>
          </FooterTop>    
          <FooterMiddle>
            <FooterGrid>
              <FooterGridLeft>
                <Address />
                <strong>See Your Building in 3D with Our 3D  Visualizer <a href='https://libertymetalstructures.sensei3d.com/' target="_blank" rel="noreferrer">Start Building Design!</a></strong>
              </FooterGridLeft>
              <FooterGridRight>
                <MenuGrid>
                  <MenuItemGrid>
                    <MenuTitle>Building Style</MenuTitle> 
                    <MenuList>              
                      <MenuItem><Link to='/metal-buildings'>- Metal Buildings</Link></MenuItem>
                      <MenuItem><Link to='/metal-barns'>- Metal Barns</Link></MenuItem>
                      <MenuItem><Link to='/metal-garages'>- Metal Garages</Link></MenuItem>
                      <MenuItem><Link to='/metal-carports'>- Metal Carports</Link></MenuItem>
                      <MenuItem><Link to='/rv-covers'>- RV Covers</Link></MenuItem>
                    </MenuList>
                  </MenuItemGrid>
                  <MenuItemGrid>
                    <MenuTitle>Liberty info</MenuTitle>
                    <MenuList> 
                      <MenuItem><Link to='/about-us'>- About LMS</Link></MenuItem>
                      <MenuItem><Link to='/why-choose-us'>- Why Choose Us</Link></MenuItem>
                      {/* <MenuItem><Link to='/reviews'>- Reviews</Link></MenuItem> */}
                      <MenuItem><Link to='/faq'>- FAQ</Link></MenuItem>
                      <MenuItem><Link to='/blog'>- Blog</Link></MenuItem>
                      <MenuItem><Link to='/contact-us'>- Contact us</Link></MenuItem>
                    </MenuList>
                  </MenuItemGrid>
                  <MenuItemGrid>            
                    <MenuTitle>Buying Guide</MenuTitle>
                    <MenuList>
                      <MenuItem><Link to='/steel-building-components'>- Steel Building Components</Link></MenuItem>
                      <MenuItem><Link to='/metal-building-roof-guide'>- Metal Building Roof Guide</Link></MenuItem>
                      <MenuItem><Link to='/site-preparation-guide'>- Site Preparation Guide</Link></MenuItem>
                      <MenuItem><Link to='/order-installation-process'>- Order/Installation Process</Link></MenuItem>
                    </MenuList>
                  </MenuItemGrid>                      
                  <MenuItemGrid>            
                    <MenuTitle>Other Links</MenuTitle>
                    <MenuList>
                      <MenuItem><Link to='/privacy-policy'>- Privacy Policy</Link></MenuItem>
                      <MenuItem><Link to='/terms-of-use'>- Terms of Use </Link></MenuItem>
                      <MenuItem><Link to='/sitemap'>- Sitemap</Link></MenuItem>
                      {/* <MenuItem><Link to='/warranty'>- Warranty</Link></MenuItem> */}
                    </MenuList>
                  </MenuItemGrid>          
                </MenuGrid>
              </FooterGridRight>
            </FooterGrid>
          </FooterMiddle>    
          {/* <FooterMiddle>         
            <LocationTitle>Providing Best Metal Buildings Across the United States</LocationTitle>
            <LocationList>
            {
              data.allContentfulState.edges.map((item) => (
                <LocationItem key={item.node.name}><Link to={item.node.url}>{item.node.name}</Link></LocationItem>  
              )) 
            }
            </LocationList>
          </FooterMiddle> */}
          <FooterBottom>   
          <Copyright>© {new Date().getFullYear()} Liberty Metal Structures. All rights reserved.</Copyright> 
          <SocialNetwork/>
        </FooterBottom>
        </FooterInner>
        
      </Container>
      <FooterCta>
        <a href="tel:8004407309" aria-label="Phone"><PrimaryButton textAfter="(800) 440-7309"  icon={<PhoneIcon />}/></a>
      </FooterCta>
    </FooterWrap>
  )
}
export default Footer