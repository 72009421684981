import React from "react"
import axios from "axios"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import CloseIcon from "../Icons/CloseIcon"
import PrimaryButton from "../Button/PrimaryButton"
import StateSelect from "../StateSelect"
import WhiteLinkButton from "../Button/WhiteLinkButton"
import CallIcon from "../Icons/CallIcon"
import {
  Modal,
  CloseButton,
  ModalDialog,
  ModalContent,
  ModalBody,
  ModalBackdrop,
} from "../Modal"
import BreakpointUp from "../Media/BreakpointUp"

const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  .gatsby-image-wrapper {
    height: 100%;
    display: block;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    background-color: rgba(3, 51, 102, 0.85);
    width: 100%;
    height: 100%;
  }
`
const ModalRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const ModalLeft = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.md`
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  `}
  .popup-title {
    color: #fff;
  }
`
const ModalRight = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.md`
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  `}
  .grid {
    padding: 30px;
    ${BreakpointUp.md`
      padding:60px 30px;      
    `}
    ${BreakpointUp.xl`
      padding:90px;
    `}
    ${BreakpointUp.xxl`
      padding:120px;
    `}
  }
  .form-action {
    margin-bottom: 0;
  }
`

const ModalHeader = styled.div`
  position: relative;
  z-index: 1;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 40px 30px 30px;
  ${BreakpointUp.md`
    padding:60px 30px;    
  `}
  ${BreakpointUp.xl`
    padding:90px 40px;
  `}
  ${BreakpointUp.xxl`
    padding:120px 70px;
  `}
`
const ModalTop = styled.div`
  position: relative;
  strong {
    position: relative;
    display: block;
    color: #fff;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 32px;
    ${BreakpointUp.md`
      font-size: 28px;
      line-height: 42px;
      `}
    ${BreakpointUp.lg`
      font-size: 32px;
      line-height: 42px;
      margin-bottom:20px;
    `}
    ${BreakpointUp.xl`
      font-size: 42px;
      line-height: 54px;
    `}
    .big-title{
      display:none;
      ${BreakpointUp.lg`
        display:block;
      `}
    }
    .small-title{
      display:block;
      ${BreakpointUp.lg`
      display:none;
      `}
    }
  }
  p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom:0;
    &.big-desc{
      display:none;
      ${BreakpointUp.lg`
      display:block;
      `}
    }
    &.small-desc{
      display:block;
      ${BreakpointUp.lg`
      display:none;
      `}
    }
  }
`
const ModalEnquiry = styled.div`
  display:none;
  ${BreakpointUp.lg`
    display:block;
  `}
  p {
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.5);
  }
  .btn {
    > .text {
      font-size: 18px;
      line-height: 24px;
      ${BreakpointUp.lg`
        font-size: 20px;
        line-height: 26px;
      `}
    }
    > .icon {
      svg {
        fill: #d51333;
      }
      + .text {
        margin-left: 5px;
      }
    }
  }
`
const ModalArrow = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: calc(100% - 90px);
  transform: translateY(-50%);
  display: none;
  ${BreakpointUp.xl`
    display: block;
    left: calc(100% - 100px);
  `}
`

class QuotePopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      source: "",
      page: "",
      form: "",
      name: "",
      email: "",
      phone_no: "",
      state: null,
      zip_code: "",
      message: "",
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    const { id, value } = event.target
    this.setState({
      [id]: value,
    })
  }
  handleChangeSelect(state) {
    this.setState({ state })
  }
  handleSubmit(event) {
    event.preventDefault()
    const { location } = this.props
    const data = {
      source: location.origin,
      page: location.href,
      form: event.target.id,
      full_name: this.state.name,
      email: this.state.email,
      mobile_no: this.state.phone_no,
      state: this.state.state.value,
      zip_code: this.state.zip_code,
      commnet: "Message: " + this.state.message,
      api_token: process.env.GATSBY_FORM_CRM_TOKEN,
    }

    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN },
    })
      .then(res => {
        this.setState({
          source: "",
          page: "",
          form: "",
          name: "",
          email: "",
          phone_no: "",
          state: "",
          zip_code: "",
          message: "",
        })
        navigate("/thank-you-request-a-quote/")
      })
      .catch(error => {
        alert(error)
      })
  }
  render() {
    const { isVisible } = this.props
    return (
      <>
        <Modal
          className={"modal fade " + (isVisible ? "show" : "")}
          style={{ display: isVisible ? "block" : "none" }}
        >
          <ModalDialog className="modal-dialog modal-xl modal-dialog-centered">
            <ModalContent className="modal-content">
              <ModalBody className="modal-body p-0">
                <CloseButton className="close" onClick={this.props.onClose}>
                  <CloseIcon />
                </CloseButton>
                <ModalRow>
                  <ModalLeft>
                    <ModalBackground>
                      <StaticImage
                        src="../../images/getquote-bg.jpg"
                        placeholder="blurred"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="getquote-bg"
                      />
                    </ModalBackground>

                    <ModalHeader>
                      <ModalTop>
                        <strong>
                          <span className="big-title">Get Started Today With Your Free Quote</span>
                          <span className="small-title">Get Started Now! </span>
                          <ModalArrow>
                            <StaticImage
                              src="../../images/arrow.png"
                              placeholder="blurred"
                              quality={95}
                              alt="arrow"
                            />
                          </ModalArrow>
                        </strong>
                        <p className="big-desc">Please fill out form, We will contact you within three business days.</p>
                        <p className="small-desc">Fill Out Requirements & Easily Price Your Building</p>
                      </ModalTop>
                      <ModalEnquiry>
                        <p>
                          If you need any Assistant, <br />
                          please call us, our Experts are Available
                        </p>
                        <a href="tel:8004407309" aria-label="Phone">
                          <WhiteLinkButton
                            textAfter="(800) 440-7309"
                            icon={<CallIcon />}
                          />
                        </a>
                      </ModalEnquiry>
                    </ModalHeader>
                  </ModalLeft>
                  <ModalRight>
                    <form
                      id="quoteform"
                      className="quoteform grid"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Name"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Email Address"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="tel"
                          id="phone_no"
                          className="form-control"
                          placeholder="Phone Number"
                          onChange={this.handleChange}
                          pattern="[0-9]{10}"
                          required
                        />
                      </div>
                      <div className="form-group form-group-select">
                        <StateSelect
                          id="state"
                          value={this.state.state}
                          onChange={this.handleChangeSelect}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="number"
                          id="zip_code"
                          className="form-control"
                          placeholder="Zip Code"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group form-group-textarea">
                        <textarea
                          type="text"
                          id="message"
                          className="form-control"
                          placeholder="Type your message here…"
                          onChange={this.handleChange}
                          required
                          title=" "
                        />
                      </div>
                      <div className="form-action">
                        <button
                          type="submit"
                          aria-label="button"
                          className="btn-block"
                        >
                          <PrimaryButton text="Submit" size="lg" />
                        </button>
                      </div>
                    </form>
                  </ModalRight>
                </ModalRow>
              </ModalBody>
            </ModalContent>
          </ModalDialog>
        </Modal>
        {isVisible && (
          <ModalBackdrop className={"fade " + (isVisible ? "show" : "")} />
        )}
      </>
    )
  }
}

export default QuotePopup
