import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import {
  DropDown,
  Grid,
  LeftCol,
  DropDownTitle,
  DropDownList,
  DropDownListItem,
  RightCol,
  BtnTool,
  ImgWrapper,
  InfoContent
} from './dropdown'
import PrimaryLinkButton from '../../Button/PrimaryLinkButton'
import ArrowNextIcon from '../../Icons/ArrowNextIcon'
import InfoIcon from '../../Icons/InfoIcon'

function MetalGaragesDropdown({ isActive }) {
  const [activeTab, setactiveTab] = useState(0);
  function Handletab(key) {
    setactiveTab(key)
  }
  return (
    <DropDown className={`dropdown-menu ${isActive ? 'active' : ''}`}>
      <Grid>
        <LeftCol>                                                                                                 
          {/* <BtnTool>
            <Link to="/metal-buildings"><PrimaryLinkButton text="Explore All Metal Buildings" icon={<ArrowNextIcon />} /></Link>
          </BtnTool> */}
          <DropDownList>
            {[
              {
                title: 'Explore All Metal Buildings',
                link: '/metal-buildings',
                InfoContent:`Welcome to the incredibly versatile world of metal buildings! The term, “metal building”, encompasses everything from prefab buildings to metal building kits to custom metal buildings. According to the 2014 Annual Report of the Metal Building Manufacturer’s Association, “Metal building systems now account for over 50% of the new, low-rise, non-residential buildings in the U.S.”.` 
              },
              {
                title: 'Commercial Buildings',
                link: '/commercial-metal-buildings',
                InfoContent:`Commercial properties are a common part of the American landscape, but when you embark on your business venture, your commercial needs are anything but ordinary. You deserve a commercial metal building that is designed for you and your unique enterprise’s needs. You want a building that will last for decades, keep your employees safe, and be aesthetically pleasing to your clients.` 
              },
              {
                title: 'Clear Span Buildings',
                link: '/clear-span-buildings',
                InfoContent:`Clear span structures provide flexibility that is unequaled by any other design concept. The brilliance of clear span architecture is in the fabrication, which is reinforced without the need for interior columns or other barriers. The interior of a clear span building is open to the floorplan of your choice. Whether you prefer an open flow commercial building, a Fengshui harmony in your residence, or enclosed stalls for your agricultural barn, the clear span buildings from Liberty Metal Buildings will deliver.` 
              },
              {
                title: 'Custom Metal Buildings',
                link: '/custom-metal-buildings',
                InfoContent:`Liberty Metal Buildings is eager to change your perception of custom metal buildings! What comes to mind when you think of metal buildings may be a drab metal box, but this couldn’t be further from the modern reality. Elegant architecture options, with wainscoting, have made custom metal buildings as stylish as conventionally constructed buildings. Custom additions, such as gables and trim, elevate the aesthetic of any carport to match a luxury home. In fact, Liberty Metal offers the use of a 3D visualizer, so that every customer can design the custom metal building of their dreams. Discover the wide world of steel structures with us, today! `
              },
              {
                title: 'Metal Workshops',
                link: '/metal-workshops',
                InfoContent:`Metal workshops from Liberty Metal Buildings are engineered to be as tough as you! Whether you are arc welding, fixing up a vintage roadster, or woodworking, your workshop should have the custom outlets, walkouts, and design elements you need. Look no further than the expertly certified metal workshops in our inventory; each one is constructed with Type 1, fire-resistant steel. With galvanized panels backed by a twenty-year warranty and roofs certified to withstand 140 mph winds, there is no more secure environment than the steel workshop. When safety and durability are the most important factors in your workshop, you want a metal workshop from Liberty! `
              },
              {
                title: 'Metal Warehouses',
                link: '/metal-warehouses',
                InfoContent:`Metal buildings have long been a popular option for factories, manufacturing facilities, and industrial warehouses. And for a good reason! Steel structures can be built to almost any size, require very little maintenance, and can be built in a fraction of the time it would take to construct a similar-sized wooden building. `
              }].map((item, index) => (
                <DropDownListItem key={index} onMouseOver={() => Handletab(index)}>
                  <Link to={item.link}>{item.title}</Link>
                  <div className="mobile-view dropdown-item-inner">                       
                    <span className="info-icon">
                      <InfoIcon />
                      <InfoContent className="info-content">{item.InfoContent}</InfoContent>
                    </span>
                  </div>
                </DropDownListItem>
              ))}
          </DropDownList>          
        </LeftCol>
        {[
            {
              title: 'Explore All Metal Buildings',
              link: '/metal-buildings',
              InfoContent:`Welcome to the incredibly versatile world of metal buildings! The term, “metal building”, encompasses everything from prefab buildings to metal building kits to custom metal buildings. According to the 2014 Annual Report of the Metal Building Manufacturer’s Association, “Metal building systems now account for over 50% of the new, low-rise, non-residential buildings in the U.S.”.` 
            },
            {
              title: 'Commercial Buildings',
              link: '/commercial-metal-buildings',
              InfoContent:`Commercial properties are a common part of the American landscape, but when you embark on your business venture, your commercial needs are anything but ordinary. You deserve a commercial metal building that is designed for you and your unique enterprise’s needs. You want a building that will last for decades, keep your employees safe, and be aesthetically pleasing to your clients.` 
            },
            {
              title: 'Clear Span Buildings',
              link: '/clear-span-buildings',
              InfoContent:`Clear span structures provide flexibility that is unequaled by any other design concept. The brilliance of clear span architecture is in the fabrication, which is reinforced without the need for interior columns or other barriers. The interior of a clear span building is open to the floorplan of your choice. Whether you prefer an open flow commercial building, a Fengshui harmony in your residence, or enclosed stalls for your agricultural barn, the clear span buildings from Liberty Metal Buildings will deliver.` 
            },
            {
              title: 'Custom Metal Buildings',
              link: '/custom-metal-buildings',
              InfoContent:`Liberty Metal Buildings is eager to change your perception of custom metal buildings! What comes to mind when you think of metal buildings may be a drab metal box, but this couldn’t be further from the modern reality. Elegant architecture options, with wainscoting, have made custom metal buildings as stylish as conventionally constructed buildings. Custom additions, such as gables and trim, elevate the aesthetic of any carport to match a luxury home. In fact, Liberty Metal offers the use of a 3D visualizer, so that every customer can design the custom metal building of their dreams. Discover the wide world of steel structures with us, today! `
            },
            {
              title: 'Metal Workshops',
              link: '/metal-workshops',
              InfoContent:`Metal workshops from Liberty Metal Buildings are engineered to be as tough as you! Whether you are arc welding, fixing up a vintage roadster, or woodworking, your workshop should have the custom outlets, walkouts, and design elements you need. Look no further than the expertly certified metal workshops in our inventory; each one is constructed with Type 1, fire-resistant steel. With galvanized panels backed by a twenty-year warranty and roofs certified to withstand 140 mph winds, there is no more secure environment than the steel workshop. When safety and durability are the most important factors in your workshop, you want a metal workshop from Liberty! `
            },
            {
              title: 'Metal Warehouses',
              link: '/metal-warehouses',
              InfoContent:`Metal buildings have long been a popular option for factories, manufacturing facilities, and industrial warehouses. And for a good reason! Steel structures can be built to almost any size, require very little maintenance, and can be built in a fraction of the time it would take to construct a similar-sized wooden building. `
            }].map((item, index) => (
            activeTab === index && 
            <RightCol className="desktop-view" key={index}>
              <DropDownTitle className='h3'>{item.title}</DropDownTitle>
              <p>{item.InfoContent}</p>
              <ImgWrapper>
                {index === 0 &&
                  <StaticImage src="../../../images/menu/metal-buildings.jpg"  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="commercial-metal-buildings" />
                }
                {index === 1 &&
                  <StaticImage src="../../../images/menu/commercial-metal-buildings.jpg"  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="commercial-metal-buildings" />
                }
                {index === 2 &&
                  <StaticImage src="../../../images/menu/clear-span-buildings.jpg"  
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="clear-span-buildings" />
                }
                {index === 3 &&
                  <StaticImage src="../../../images/menu/custom-building.jpg"  
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="custom-building" />
                }
                {index === 4 &&
                  <StaticImage src="../../../images/menu/metal-workshop.jpg"  
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="metal-workshop" />
                }
                {index === 5 &&
                  <StaticImage src="../../../images/menu/metal-warehouses.jpg"  
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="metal-warehouses" />
                }
              </ImgWrapper>
              <BtnTool>
                <Link to={item.link}><PrimaryLinkButton text={item.title} icon={<ArrowNextIcon />} /></Link>
              </BtnTool>
            </RightCol>
          ))}
      </Grid>
    </DropDown>
  )
}

export default MetalGaragesDropdown
