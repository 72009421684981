import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import {
  DropDown,
  Grid,
  LeftCol,
  DropDownTitle,
  DropDownList,
  DropDownListItem,
  RightCol,
  BtnTool,
  ImgWrapper,
  InfoContent
} from './dropdown'
import PrimaryLinkButton from '../../Button/PrimaryLinkButton'
import ArrowNextIcon from '../../Icons/ArrowNextIcon'
import InfoIcon from '../../Icons/InfoIcon'



function MetalBuildingDropdown({ isActive }) {
  const [activeTab, setactiveTab] = useState(0);
  function Handletab(key) {
    setactiveTab(key)
  }
  return (
    <DropDown className={`dropdown-menu ${isActive ? 'active' : ''}`}>
      <Grid>
        <LeftCol>
          {/* <BtnTool>
            <Link to="/metal-barns"><PrimaryLinkButton text="Explore All Metal Barns" icon={<ArrowNextIcon />} /></Link>
          </BtnTool> */}
          <DropDownList>
            {[
                {
                  title: 'Explore All Metal Barns',
                  link: '/metal-barns',
                  InfoContent:`The modern barn has evolved past its humble beginnings. Historically, a barn was a moldering wooden structure. It was painted red and stood for a few decades before crumbling beneath termites and rot. Now, the metal barn is built to last a lifetime, with a cold-formed steel frame and reinforced panels that are certified to withstand extreme weather. ` 
                },
                {
                  title: 'Continuous Roof Barns',
                  link: '/continuous-roof-barns',
                  InfoContent:`The continuous roof barn by Liberty Metal Structures is a beautiful example of clear-span engineering, featuring three separate sections beneath one sturdy roof. The generous center can measure up to 40’ in width, providing enough space for livestock and machinery. Incredible flexibility accompanies every model, with two spacious side storage areas customizable to your needs. Park your RV or host a dance in the versatile space offered by a soaring, continuous roof. Securely built using durable steel, your continuous roof barn will stand for decades and withstand any weather. The perfect barn is waiting for you, here at Liberty! ` 
                },
                {
                  title: 'A-Frame Vertical Roof Barns',
                  link: '/a-frame-vertical-roof-barns',
                  InfoContent:`A-frame vertical roof barns are some of the most resilient structures available today. They’re strong, incredibly durable, and capable of being installed in far less time than it takes to build similarly sized wooden structures. And no matter what design or style you choose, you can’t go wrong by choosing a metal building.` 
                },
                {
                  title: 'A-Frame Horizontal Roof Barns',
                  link: '/a-frame-horizontal-roof-barns',
                  InfoContent:`A-frame horizontal roof barns are a fantastic, long-lasting metal building option that can be used for a wide variety of different applications. They’re strong, durable, easy to maintain, and capable of being built in a fraction of the time compared to wooden buildings. And with steel structures being one of the most affordable construction options on the market, you'll save money before, during, and long after your building has been installed.`
                },
                {
                  title: 'Regular Roof Barns',
                  link: '/regular-roof-barns',
                  InfoContent:`Regular roof barns are some of the most economical structures available on the market today. Strong, durable, and capable of being installed in a fraction of the time it would take you to construct one from wood, you can’t go wrong choosing steel for your next barn, garage, carport, or commercial building.`
                }].map((item, index) => (
                  <DropDownListItem key={index} onMouseOver={() => Handletab(index)}>
                      <Link to={item.link}>{item.title}</Link>
                      <div className="mobile-view dropdown-item-inner">                       
                        <span className="info-icon">
                          <InfoIcon />
                          <InfoContent className="info-content">{item.InfoContent}</InfoContent>
                        </span>
                      </div>
                    </DropDownListItem>
                ))}
          </DropDownList>
          
        </LeftCol>
        {[
            {
              title: 'Explore All Metal Barns',
              link: '/metal-barns',
              InfoContent:`The modern barn has evolved past its humble beginnings. Historically, a barn was a moldering wooden structure. It was painted red and stood for a few decades before crumbling beneath termites and rot. Now, the metal barn is built to last a lifetime, with a cold-formed steel frame and reinforced panels that are certified to withstand extreme weather. ` 
            },
            {
              title: 'Continuous Roof Barns',
              link: '/continuous-roof-barns',
              InfoContent:`The continuous roof barn by Liberty Metal Structures is a beautiful example of clear-span engineering, featuring three separate sections beneath one sturdy roof. The generous center can measure up to 40’ in width, providing enough space for livestock and machinery. Incredible flexibility accompanies every model, with two spacious side storage areas customizable to your needs. Park your RV or host a dance in the versatile space offered by a soaring, continuous roof. Securely built using durable steel, your continuous roof barn will stand for decades and withstand any weather. The perfect barn is waiting for you, here at Liberty! ` 
            },
            {
              title: 'A-Frame Vertical Roof Barns',
              link: '/a-frame-vertical-roof-barns',
              InfoContent:`A-frame vertical roof barns are some of the most resilient structures available today. They’re strong, incredibly durable, and capable of being installed in far less time than it takes to build similarly sized wooden structures. And no matter what design or style you choose, you can’t go wrong by choosing a metal building.` 
            },
            {
              title: 'A-Frame Horizontal Roof Barns',
              link: '/a-frame-horizontal-roof-barns',
              InfoContent:`A-frame horizontal roof barns are a fantastic, long-lasting metal building option that can be used for a wide variety of different applications. They’re strong, durable, easy to maintain, and capable of being built in a fraction of the time compared to wooden buildings. And with steel structures being one of the most affordable construction options on the market, you'll save money before, during, and long after your building has been installed.`
            },
            {
              title: 'Regular Roof Barns',
              link: '/regular-roof-barns',
              InfoContent:`Regular roof barns are some of the most economical structures available on the market today. Strong, durable, and capable of being installed in a fraction of the time it would take you to construct one from wood, you can’t go wrong choosing steel for your next barn, garage, carport, or commercial building.`
            }].map((item, index) => (
            activeTab === index && 
            <RightCol className="desktop-view" key={index}>
              <DropDownTitle className='h3'>{item.title}</DropDownTitle>
              <p>{item.InfoContent}</p>
              <ImgWrapper>
                {index === 0 &&
                  <StaticImage src="../../../images/menu/metal-barns.jpg"  
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="metal-barns" />
                }
                {index === 1 &&
                  <StaticImage src="../../../images/menu/continuous-barn.jpg"  
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="continuous-barn" />
                }
                {index === 2 &&
                  <StaticImage src="../../../images/menu/vertical-barn.jpg"  
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="vertical-barn" />
                }
                {index === 3 &&
                  <StaticImage src="../../../images/menu/a-frame-barn.jpg"  
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="a-frame-barn" />
                }
                {index === 4 &&
                  <StaticImage src="../../../images/menu/regular-barn.jpg"  
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="regular-barn" />
                }
              </ImgWrapper>
              <BtnTool>
                <Link to={item.link}><PrimaryLinkButton text={item.title} icon={<ArrowNextIcon />} /></Link>
              </BtnTool>
            </RightCol>
          ))}
      </Grid>
    </DropDown>
  )
}

export default MetalBuildingDropdown
