import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import {
  DropDown,
  Grid,
  LeftCol,
  DropDownTitle,
  DropDownList,
  DropDownListItem,
  RightCol,
  BtnTool,
  ImgWrapper,
  InfoContent
} from './dropdown'
import PrimaryLinkButton from '../../Button/PrimaryLinkButton'
import ArrowNextIcon from '../../Icons/ArrowNextIcon'
import InfoIcon from '../../Icons/InfoIcon'



function MetalBuildingDropdown({ isActive }) {
  const [activeTab, setactiveTab] = useState(0);
  function Handletab(key) {
    setactiveTab(key)
  }
  return (
    <DropDown className={`dropdown-menu ${isActive ? 'active' : ''}`}>
      <Grid>
        <LeftCol>
          {/* <BtnTool>
            <Link to="/metal-garages"><PrimaryLinkButton text="Explore All Metal Garages" icon={<ArrowNextIcon />} /></Link>
          </BtnTool> */}
          <DropDownList>
            {[
                {
                title: 'Explore All Metal Garages',
                link: '/metal-garages',
                InfoContent:`You may already recognize steel for its durability and strength, but did you know that steel buildings are more quickly installed and more affordable than traditionally constructed buildings? According to the 2014 Annual Report of the Metal Building Manufacturer’s Association, ”Metal building systems now account for over 50% of the new, low-rise, non-residential buildings in the U.S.” With their eco-friendly construction, low maintenance, and energy efficiency, metal garages are the best decision you can make!`  
                },
                {
                title: 'A-Frame Vertical Roof Garages',
                link: '/a-frame-vertical-roof-garages',
                InfoContent:`A-Frame vertical roof garages are some of the most reliable structures on the market. They're incredibly strong, durable as can be, and capable of being installed in a fraction of the time it takes to build similar wooden structures. And no matter what design or style you need, you can't go wrong with a metal building.`  
                },
                {
                title: 'A-Frame Horizontal Roof Garages',
                link: '/a-frame-horizontal-roof-garages',
                InfoContent:`The A-frame horizontal roof garage from Liberty Metal Buildings is a classic model with a traditional aesthetic. The A-frame roof, also known more commonly as “boxed-eave”, is a common architectural shape in the United States. Our experts are standing by to help you customize your perfect garage. Choose from a variety of sizes and styles, knowing that every one of our steel buildings is engineered for durability and stability. Your vehicles are an investment worth protecting; let us help you design the perfect, A-frame garage. ` 
                },
                {
                title: 'Regular Roof Garages',
                link: '/regular-roof-garages',
                InfoContent:`The regular roof metal garage is a durable, affordable solution for the average American. Engineered with tough steel, this structure is built to last for a lifetime, resisting fire, mold, and weather. Your vehicles, tools, and storage will be secure within the galvanized panel walls built by Liberty Metal Buildings. We take pride in providing you with a uniquely designed and affordably priced garage!` 
                }].map((item, index) => (
                  <DropDownListItem key={index} onMouseOver={() => Handletab(index)}>
                      <Link to={item.link}>{item.title}</Link>
                      <div className="mobile-view dropdown-item-inner">                       
                        <span className="info-icon">
                          <InfoIcon />
                          <InfoContent className="info-content">{item.InfoContent}</InfoContent>
                        </span>
                      </div>
                    </DropDownListItem>
                ))}
          </DropDownList>          
        </LeftCol>
        {[
            {
              title: 'Explore All Metal Garages',
              link: '/metal-garages',
              InfoContent:`You may already recognize steel for its durability and strength, but did you know that steel buildings are more quickly installed and more affordable than traditionally constructed buildings? According to the 2014 Annual Report of the Metal Building Manufacturer’s Association, ”Metal building systems now account for over 50% of the new, low-rise, non-residential buildings in the U.S.” With their eco-friendly construction, low maintenance, and energy efficiency, metal garages are the best decision you can make!`  
            },
            {
            title: 'A-Frame Vertical Roof Garages',
            link: '/a-frame-vertical-roof-garages',
            InfoContent:`A-Frame vertical roof garages are some of the most reliable structures on the market. They're incredibly strong, durable as can be, and capable of being installed in a fraction of the time it takes to build similar wooden structures. And no matter what design or style you need, you can't go wrong with a metal building.`  
            },
            {
            title: 'A-Frame Horizontal Roof Garages',
            link: '/a-frame-horizontal-roof-garages',
            InfoContent:`The A-frame horizontal roof garage from Liberty Metal Buildings is a classic model with a traditional aesthetic. The A-frame roof, also known more commonly as “boxed-eave”, is a common architectural shape in the United States. Our experts are standing by to help you customize your perfect garage. Choose from a variety of sizes and styles, knowing that every one of our steel buildings is engineered for durability and stability. Your vehicles are an investment worth protecting; let us help you design the perfect, A-frame garage. ` 
            },
            {
            title: 'Regular Roof Garages',
            link: '/regular-roof-garages',
            InfoContent:`The regular roof metal garage is a durable, affordable solution for the average American. Engineered with tough steel, this structure is built to last for a lifetime, resisting fire, mold, and weather. Your vehicles, tools, and storage will be secure within the galvanized panel walls built by Liberty Metal Buildings. We take pride in providing you with a uniquely designed and affordably priced garage!` 
            }].map((item, index) => (
            activeTab === index && 
            <RightCol className="desktop-view" key={index}>
              <DropDownTitle className='h3'>{item.title}</DropDownTitle>
              <p>{item.InfoContent}</p>
              <ImgWrapper>
                {index === 0 &&
                  <StaticImage src="../../../images/menu/metal-garages.jpg" 
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="metal-garages" />
                }
                {index === 1 &&
                  <StaticImage src="../../../images/menu/vertical-garages.jpg" 
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="vertical-garages" />
                }
                {index === 2 &&
                  <StaticImage src="../../../images/menu/a-frame-garages.jpg" 
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="a-frame-garages" />
                }
                {index === 3 &&
                  <StaticImage src="../../../images/menu/regular-garages.jpg" 
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="regular-garages" />
                }
              </ImgWrapper>
              <BtnTool>
                <Link to={item.link}><PrimaryLinkButton text={item.title} icon={<ArrowNextIcon />} /></Link>
              </BtnTool>
            </RightCol>
          ))}
      </Grid>
    </DropDown>
  )
}

export default MetalBuildingDropdown
