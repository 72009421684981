import React, {useState, useEffect} from "react"
import styled, { keyframes } from 'styled-components'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Container } from "../components/Section"
import BreakpointDown from "../components/Media/BreakpointDown"
import BreakpointUp from "../components/Media/BreakpointUp"
import DefaultButton from "../components/Button/DefaultButton"
import PrimaryButton from "../components/Button/PrimaryButton"
import ArrowDownIcon from "../components/Icons/ArrowDownIcon"
import HomeIcon from "../components/Icons/HomeIcon"
import PhoneIcon from "../components/Icons/PhoneIcon"
import CloseIcon from "../components/Icons/CloseIcon"
import RequestModal from "../components/RequestModal"
import { StaticImage } from "gatsby-plugin-image"

import MetalBuildingDropdown from './Header/DropDown/meetal-building-dropdown'
import BarnDropdown from './Header/DropDown/barn-dropdown'
import MetalGaragesDropdown from './Header/DropDown/metal-garages-dropdown'
import MetalCarportsDropdown from './Header/DropDown/metal-carports-dropdown'
import RVCoversDropdown from './Header/DropDown/rv-covers-dropdown'
import ResourcesDropdown from './Header/DropDown/resources-dropdown'
import AboutDropdown from './Header/DropDown/about-dropdown'

const headerSticky = keyframes`
  from{
    transform:translateY(-140px);
  }
  top{
    transform:translateY(0);

  }
`
const HeaderWrapper = styled.header`
  top:0;
  left:0;
  right:0;
  z-index:21;
  height:64px;
  position:fixed; 
  background-color:#fff;
  box-shadow:0 25px 55px rgba(0,75,155,0.25);
  ${BreakpointUp.xl`
    background-color:transparent;
    box-shadow:none;
    position:absolute; 
    height:94px;
  `}
  ${BreakpointUp.xxl`  
    height:114px;
  `}
  &.header-sticky{
    ${BreakpointUp.xl`
      animation: ${headerSticky} 0.6s ease-in-out both;
      position: fixed;
      top:0;
      background-color:#fff;
      box-shadow:0 25px 55px rgba(0,75,155,0.25);
    `}
  }
`
const HeaderInner = styled.div`  
  display: flex;
  justify-content: space-between;
  margin:10px 0;
  ${BreakpointUp.xl`  
    margin:0;
  `}
`

const NavBrand = styled(Link)`     
  outline:none;
  overflow: hidden;  
  position:relative;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  display: flex;
  align-items: center;
  .gatsby-image-wrapper{
    display:block;
    max-width: 64px;
    ${BreakpointUp.xl`  
      max-width: 114px;
    `}
    ${BreakpointUp.xxl`  
      max-width: 136px;
    `}
  }
`

const NavbarNav = styled.div`
  display: flex;
  list-style: none;
  align-items:center;
  justify-content: space-around;
  ${BreakpointUp.xl`  
    margin-right:15px;
  `}
  ${BreakpointDown.xl`
    flex-direction:column;
    margin:0;
    width:100%;
    flex: auto;
    justify-content: inherit;
  `}
`
const NavItem = styled.div`   
  margin:0;
  ${BreakpointDown.xl`
    border-top: 1px solid rgba(240,248,255,0.20);
    &:first-child{
      border-top:0; 
    }
  `}
  ${BreakpointUp.xl`
    &:last-child{
      .nav-link{ padding-right:0}
    }
  `}
  .dropdown-menu{
    ${BreakpointUp.xl`      
      display: block;
      visibility: hidden;
      opacity: 0;      
      transition: all 0.4s cubic-bezier(0.3, 0, 0, 1.3) 0s;
    `}
  }
  &.nav-static{
    position: relative;
    .dropdown-menu{
      min-width:320px;
      box-shadow: 0 25px 55px rgb(0 75 155 / 25%);
      background: #F0F8FF;
      padding: 20px 30px 30px;
      ${BreakpointUp.xl`        
        background-color: #fff;
        padding:20px 0;
        transform: scale(0.8) translateY(-12%);
      `}
      ul{
        li{
          margin-bottom:0;
          a{
            width: 100%;
            ${BreakpointUp.xl`
              color:#000;
              font-size: 16px;
              line-height: 28px;
              padding: 6px 25px;
              width: auto;
            `}
            &:hover{
              ${BreakpointUp.xl`
                color:#033366;
              `}
            }
            &:after{display:none;}
          }
        }
      }
    }
  }
  &:hover{
    cursor:pointer;    
    .dropdown-menu{
      ${BreakpointUp.xl`
        display: block;
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
        transform: none;
      `}
    }
  }
  ${BreakpointDown.xl`
    width:100%;
  `}  
  &.active{
    & .nav-link{
      color:#211F28; 
      background-color:#F9F9F9;
    }
  }
  &:last-child{
    .dropdown-menu{
      ${BreakpointUp.xl`
        right:0;
        left:auto;
      `}
    }
  }
  
`

const NavLink = styled.span`
  display: flex;
  align-items:center;  
  font-weight: 700;
  color:#fff;
  cursor:pointer;
  white-space:nowrap;
  position:relative;
  font-size: 14px;
  line-height: 20px;
  text-transform:uppercase;
  letter-spacing: 1px;
  padding:12px 10px;  
  ${BreakpointUp.xl`  
    padding: 37px 10px;
    color:#033366;
  `}  
  ${BreakpointUp.xxl`  
    padding: 47px 15px;
  `}
  .text{
    + .caret{
      margin-left:6px;
      ${BreakpointDown.xl`
       margin-left:auto;
      `}
    }
  }
  .caret{
    display: flex;
    align-items:center;
    justify-content:center;
    transition: all 0.3s ease;
    width: 10px;
    height: 10px;
    > svg{
      fill:#EC1C24;
      width:10px;
      ${BreakpointDown.xl`
       fill:#fff;
      `}
    }
  }
  a{
    color:#fff;
    ${BreakpointUp.xl`
      color:#033366;
    `}
    &:hover{
      ${BreakpointUp.xl`
        color: #EC1C24;
      `}
    }
  }
  &:hover{
    color: #EC1C24;
    ${BreakpointDown.xl`
      color:#fff;
    `}
    > svg{
      fill:#EC1C24;
      ${BreakpointDown.xl`
       fill:#fff;
      `}
    }
    
  }  
`
const Navbar = styled.div`  
  /* position:relative; */
  width: 100%;
  padding:0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
`
const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .close-menu {
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 99;
    display: block;
    svg{
      fill:#fff;
      fill: #fff;
      width: 20px;
      height: 15px;
    }
    ${BreakpointUp.xl` 
      display: none;
    `}
  }
  
  ${BreakpointDown.xl`  
   
   padding: 40px 30px;  
    flex-direction: column-reverse;
    width: 0;
    position: absolute;
    top: -10px;
    right: 0;
    height: 100vh;
    z-index: 99;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    transform: translate3d(100%,0,0);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 15px;
    overflow-y: auto;
    display:block;
    background: #033366;
  `}
`
const TopNav = styled.div`
  text-align: right;    
  display: flex;
  align-items: center;
  justify-content: flex-end;  
  margin:0 -5px;  
  & .hamburger-menu{
    display: none;
    ${BreakpointDown.xl`
      display: inline-flex;
    `}
  }
  .btn{
    ${BreakpointDown.xxl`
      padding: 9px 12px;
      font-size: 14px;
    `}
    ${BreakpointDown.xxl`
      padding: 8px 15px;
      max-width: 48px;
      width: 100%;
    `}
    > .text{
      ${BreakpointDown.xxl`
        display:none;
      `}
    }
    > .icon{
      ${BreakpointDown.xxl`
        margin-left:0;
      `}
    }
  }
`
const TopNavItem = styled.div`
  text-align:center;
  position: relative;
  padding:0 5px;
  a{
    display:inline-block;
  } 
  &.estimator-btn{
    display:none;
    ${BreakpointUp.xl`
    display:block;
    `}
  }
  &.quote-btn{
    display:block;
    ${BreakpointUp.xl`
    display:none;
    `}
    .btn{
      max-width: inherit;
      > .text{
        ${BreakpointDown.xxl`
          display: inline-flex;
        `}
      }
    } 
  }

`



function Header({isHeader, location}) {
  const [isMenu, setisMenu]=useState(true)
  const [isHeaderSticky, setisHeaderSticky] = useState(false)
  const [isDropDown, setDropDown] = useState(false)

  const [isQuoteVisible, setIsQuoteVisible] = useState(false)
  const HandleModalOpen = () => {
    setIsQuoteVisible(true)
    document.querySelector("body").classList.add("modal-open")
  }
  const HandleModalClose = () => {
    setIsQuoteVisible(false)
    document.querySelector("body").classList.remove("modal-open")
  }

  useEffect(() => {
    document.addEventListener('scroll', headerSticky)
    return () => {
      document.body.classList.remove('menu-open')
      document.removeEventListener('scroll', headerSticky)
    }
  }, [])
   
  function hangleDropDown(index) {
    if (isDropDown === index) {
      return setDropDown(null)
    }
    setDropDown(index);
    
  }

  function headerSticky() {
    if (window.scrollY > 0) {
      setisHeaderSticky(true)
    }
    else {
      setisHeaderSticky(false)
    }
  }
  function toggleActive() {
   setisMenu(!isMenu)
    document.body.classList.toggle('menu-open', isMenu)
  }
  console.log(location)
  return (
    <HeaderWrapper className={isHeaderSticky || isHeader ? "header header-sticky" : "header"}>
      <Container maxWidth="100%" pl="3%" pr="3%">
        <HeaderInner>
          <NavBrand to ="/">            
            <StaticImage
              src="../svg/logo.svg"
              placeholder="blurred"                          
              alt="Liberty Metal Buildings"
            />
          </NavBrand>            
          <Navbar>      
            <Nav className="nav">
            <button type="button" aria-label="Close Menu" onClick={toggleActive} className="close-menu">
            <CloseIcon />
            </button>
              <NavbarNav>
                <NavItem>
                    <NavLink className="nav-link">
                      <Link to="/metal-buildings" role="button" tabIndex="0" className="text">Metal buildings</Link><button aria-label="span" className="caret" onClick={() => hangleDropDown(0)}>{isDropDown === 0 ? <CloseIcon /> : <ArrowDownIcon />}</button>
                    </NavLink>
                    <MetalBuildingDropdown isActive={isDropDown === 0 ? true : false }/>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link">
                    <Link to="/metal-barns" role="button" tabIndex="0" className="text">Barns</Link><button aria-label="span" className="caret"  onClick={() => hangleDropDown(1)}>{isDropDown === 1 ? <CloseIcon /> : <ArrowDownIcon />}</button>
                    </NavLink>
                    <BarnDropdown isActive={isDropDown === 1 ? true : false } />
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link">
                    <Link to="/metal-garages" role="button" tabIndex="0" className="text">Garages</Link><button aria-label="span" className="caret"  onClick={() => hangleDropDown(2)}>{isDropDown === 2 ? <CloseIcon /> : <ArrowDownIcon />}</button>
                    </NavLink>
                    <MetalGaragesDropdown isActive={isDropDown === 2 ? true : false } />
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link">
                    <Link to="/metal-carports" role="button" tabIndex="0" className="text">Carports</Link><button aria-label="span" className="caret"  onClick={() => hangleDropDown(3)}>{isDropDown === 3 ? <CloseIcon /> : <ArrowDownIcon />}</button>
                    </NavLink>
                    <MetalCarportsDropdown isActive={isDropDown === 3 ? true : false } />
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link">
                    <Link to="/rv-covers" role="button" tabIndex="0" className="text">RV Covers</Link><button aria-label="span" className="caret"  onClick={() => hangleDropDown(4)}>{isDropDown === 4 ? <CloseIcon /> : <ArrowDownIcon />}</button>
                    </NavLink>
                    <RVCoversDropdown isActive={isDropDown === 4 ? true : false } />
                </NavItem>
                <NavItem className="nav-static">
                  <NavLink className="nav-link">
                  <span role="button" tabIndex="0" className="text">Resources</span><button aria-label="span" className="caret" onClick={() => hangleDropDown(5)}>{isDropDown === 5 ? <CloseIcon /> : <ArrowDownIcon />}</button>
                  </NavLink>
                  <ResourcesDropdown isActive={isDropDown === 5 ? true : false } />
                </NavItem>
                <NavItem className="nav-static">
                  <NavLink className="nav-link">
                  <Link to="/about-us" role="button" tabIndex="0" className="text">About Us</Link><button aria-label="span" className="caret" onClick={() => hangleDropDown(6)}>{isDropDown === 6 ? <CloseIcon /> : <ArrowDownIcon />}</button>
                  </NavLink>
                  <AboutDropdown isActive={isDropDown === 6 ? true : false } />
                </NavItem>
              </NavbarNav>
            </Nav>        
            <TopNav>  
              <TopNavItem className="quote-btn"><button type="button" aria-label="button" onClick={HandleModalOpen}><DefaultButton text="Free Quote"/></button></TopNavItem>
              <TopNavItem className="estimator-btn"><a href='https://libertymetalstructures.sensei3d.com/' target="_blank" without rel="noreferrer" aria-label="3D Designer"><DefaultButton textAfter="3D Designer"  icon={<HomeIcon />}/></a></TopNavItem>
              <TopNavItem className="phone-btn"><a href="tel:8004407309" aria-label="Phone"><PrimaryButton textAfter="(800) 440-7309"  icon={<PhoneIcon />}/></a></TopNavItem>
              <TopNavItem className="hamburger-menu"><button  type="button" aria-label="Hamburger Menu" onClick={toggleActive} className="hamburger"><i className="icon"></i></button></TopNavItem>              
            </TopNav>
          </Navbar> 
        </HeaderInner>     
      </Container>        
      <RequestModal
        isVisible={isQuoteVisible}
        onClose={HandleModalClose}
        location={location}
      />    
    </HeaderWrapper>   
    )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header