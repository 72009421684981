import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const WhiteLinkBtn = styled(BaseButton)`		
    color:#fff;
    padding-left:0 !important;
    padding-right:0 !important;
	& .icon{
		> svg{
			fill:#fff;
		}
	}
	&:hover{
        color:#D51333;
        & .icon{
            > svg{
                fill:#D51333;
            }
	    }
	}
`

const WhiteLinkButton = (props) => {
	const { icon, text, size, textAfter } = props;
	return(
		<WhiteLinkBtn className={`btn ${size ? `btn-${size}` : ''} `}>
            {textAfter ?
                (
					<>
						{ icon &&
							<span className='icon'>{icon}</span>
						}
						{ textAfter &&
							<span className='text'>{textAfter}</span>
						}
					</>
				):(
					<>
						{ text &&
							<span className='text'>{text}</span>
						}
						{ icon &&
							<span className='icon'>{icon}</span>
						}
					</>
				)
			}

		</WhiteLinkBtn>
	)
}

export default WhiteLinkButton