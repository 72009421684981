import React from "react"
import styled from "styled-components"
import MapPointerIcon from "../Icons/MapPointerIcon"
import PhoneIcon from "../Icons/PhoneIcon"
import PaperplaneIcon from "../Icons/PaperplaneIcon"
import HoursIcon from "../Icons/HoursIcon"
import BreakpointDown from "../Media/BreakpointDown"
import BreakpointUp from "../Media/BreakpointUp"

const ListInfoTitle = styled.div`
  color:#000;
  font-weight:700;
  line-height:30px;
  font-size:18px;
  margin-bottom:10px;
`
const ListInfo = styled.div`
  margin:0 0 20px;
  ${BreakpointDown.lg`
    display: flex;
    flex-wrap: wrap;
  `}
  
`
const ListItemInfo = styled.div`  
  display:flex;
  flex-wrap:nowrap;  
  margin-bottom:0;
  padding:5px 0;
  ${BreakpointDown.lg`
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointDown.sm`
    flex: 0 0 100%;
    max-width: 100%;
  `}
  > .icon{
    width: 24px;
    height: 26px;
    display: flex;
    align-items: center;    
    & svg{
      fill:#033366;
    }
    + .text{
      margin-left:15px;
    }
  }
  .text{
    color:#666;
    font-size:14px;
    line-height:30px;
    ${BreakpointUp.xxl`     
      font-size:16px;
      line-height:38px;
    `}
    label{
      display: block;
      line-height: 28px;
    }
    small{
      display: block;
      line-height: 20px;
    }
  }
  & a{
    display:inline-block;      
    color:#666;   
    &:hover{
      color:#000;
      text-decoration:none;
    }
  }
  .e-mail{
    & a{
      text-decoration:underline;
      color:#033366;
      &:hover{
        color:#000;
        text-decoration:none;
      }
    }
  }
`

const Address = () => {
  return (
    <>
      <ListInfoTitle>Liberty Metal Structures</ListInfoTitle>
      <ListInfo className="list-info">
        <ListItemInfo>
          <span className="icon"><MapPointerIcon /></span>
          <span className="text">796 W Lebanon St. Mount Airy, NC 27030</span>
        </ListItemInfo>
        <ListItemInfo>
          <span className="icon"><PhoneIcon /></span>
          <span className="text"><a href="tel:8004407309">(800) 440-7309</a> <a href="tel:18004407309">Toll Free: 18004407309</a></span>
        </ListItemInfo> 
        <ListItemInfo>
            <span className="icon"><PaperplaneIcon /></span>
            <span className="text e-mail"><a href="mailto:sales@libertymetalstructures.com">sales@libertymetalstructures.com</a></span>
        </ListItemInfo>
        <ListItemInfo>
          <span className="icon"><HoursIcon /></span>
          <span className="text">
            <label aria-label="title">Operating Hours:</label>
            <small>Monday to Friday -  8 AM to 5 PM</small>
            <small>Saturday - 8 AM to 12 PM</small>
          </span>
        </ListItemInfo>
      </ListInfo>
    </>
  )
}

export default Address
