import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const PrimaryBtn = styled(BaseButton)`
	background: #D51333;
	color:#fff;		
	&:after {		
		background: #A20000;
	}
	& .icon{
		> svg{
			fill:#fff;
		}
	}
`

const PrimaryButton = (props) => {
	const { icon, text, size, textAfter } = props;
	return(
		<PrimaryBtn className={`btn ${size ? `btn-${size}` : ''} `}>
			{textAfter ?
                (
					<>
						{ icon &&
							<span className='icon'>{icon}</span>
						}
						{ textAfter &&
							<span className='text'>{textAfter}</span>
						}
					</>
				):(
					<>
						{ text &&
							<span className='text'>{text}</span>
						}
						{ icon &&
							<span className='icon'>{icon}</span>
						}
					</>
				)
			}
		</PrimaryBtn>
	)
}

export default PrimaryButton