import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import {
  DropDown,
  Grid,
  LeftCol,
  DropDownTitle,
  DropDownList,
  DropDownListItem,
  RightCol,
  BtnTool,
  ImgWrapper,
  InfoContent
} from './dropdown'
import PrimaryLinkButton from '../../Button/PrimaryLinkButton'
import ArrowNextIcon from '../../Icons/ArrowNextIcon'
import InfoIcon from '../../Icons/InfoIcon'


function RVCoversDropdown({ isActive }) {
  const [activeTab, setactiveTab] = useState(0);
  function Handletab(key) {
    setactiveTab(key)
  }
  return (
    <DropDown className={`dropdown-menu ${isActive ? 'active' : ''}`}>
      <Grid>
        <LeftCol>
          {/* <BtnTool>
            <Link to="/rv-covers"><PrimaryLinkButton text="Explore All RV Covers" icon={<ArrowNextIcon />} /></Link>
          </BtnTool> */}
          <DropDownList>
          {[
            {
              title: 'Explore All RV Covers',
              link: '/rv-covers',
              InfoContent:`Your home away from home deserves the best in coverage while it waits for your next adventure. At Liberty Metal Structures, we are committed to engineering the highest quality steel covers for your RV. Protect your investment from UV rays, rain, hail, and high winds with our cold-formed steel frames and galvanized panels. Our RV covers are customizable and backed by warranties that prove the confidence we have in every model.` 
            },
            {
                title: 'A-Frame Vertical Roof RV Covers',
                link: '/a-frame-vertical-roof-rv-covers',
                InfoContent:`At Liberty Metal Buildings, we are committed to engineering the highest quality steel covers for your RV. Protect your investment from UV rays, rain, hail, and high winds with our vertical roof RV covers! Our metal RV covers are backed by a twenty-year rust-through warranty that proves the confidence we have in every model. Your RV keeps you warm and safe during your camping escapades; shouldn’t you offer your RV the same loving care?` 
            },
            {
                title: 'A-Frame Horizontal Roof RV Covers',
                link: '/a-frame-horizontal-roof-rv-covers',
                InfoContent:`Create the timeless, classic RV cover you want with the customizing experts at Liberty. The modern steel cover is so much more than a simple metal box. The A-frame roof, also known more commonly as “boxed-eave”, is a common architectural shape in the United States. You can style your metal RV cover to match your home.` 
            },
            {
                title: 'Regular Roof RV Covers',
                link: '/regular-roof-rv-covers',
                InfoContent:`Liberty Metal Buildings is proud to provide affordable, durable RV covers with expert engineering. Our certified structures are built to last for a lifetime and backed by our twenty-year, rust-through warranty. Regular roof RV covers can be customized with gables and J-trim for a polished appearance. You can recognize the regular roof by its uniquely curved edges.`
            }].map((item, index) => (
                  <DropDownListItem key={index} onMouseOver={() => Handletab(index)}>
                      <Link to={item.link}>{item.title}</Link>
                      <div className="mobile-view dropdown-item-inner">                       
                        <span className="info-icon">
                          <InfoIcon />
                          <InfoContent className="info-content">{item.InfoContent}</InfoContent>
                        </span>
                      </div>
                    </DropDownListItem>
                ))}
          </DropDownList>
        </LeftCol>
        {[
            {
              title: 'Explore All RV Covers',
              link: '/rv-covers',
              InfoContent:`Your home away from home deserves the best in coverage while it waits for your next adventure. At Liberty Metal Structures, we are committed to engineering the highest quality steel covers for your RV. Protect your investment from UV rays, rain, hail, and high winds with our cold-formed steel frames and galvanized panels. Our RV covers are customizable and backed by warranties that prove the confidence we have in every model.` 
            },
            {
                title: 'A-Frame Vertical Roof RV Covers',
                link: '/a-frame-vertical-roof-rv-covers',
                InfoContent:`At Liberty Metal Buildings, we are committed to engineering the highest quality steel covers for your RV. Protect your investment from UV rays, rain, hail, and high winds with our vertical roof RV covers! Our metal RV covers are backed by a twenty-year rust-through warranty that proves the confidence we have in every model. Your RV keeps you warm and safe during your camping escapades; shouldn’t you offer your RV the same loving care?` 
            },
            {
                title: 'A-Frame Horizontal Roof RV Covers',
                link: '/a-frame-horizontal-roof-rv-covers',
                InfoContent:`Create the timeless, classic RV cover you want with the customizing experts at Liberty. The modern steel cover is so much more than a simple metal box. The A-frame roof, also known more commonly as “boxed-eave”, is a common architectural shape in the United States. You can style your metal RV cover to match your home.` 
            },
            {
                title: 'Regular Roof RV Covers',
                link: '/regular-roof-rv-covers',
                InfoContent:`Liberty Metal Buildings is proud to provide affordable, durable RV covers with expert engineering. Our certified structures are built to last for a lifetime and backed by our twenty-year, rust-through warranty. Regular roof RV covers can be customized with gables and J-trim for a polished appearance. You can recognize the regular roof by its uniquely curved edges.`
            }].map((item, index) => (
            activeTab === index && 
            <RightCol className="desktop-view" key={index}>
              <DropDownTitle className='h3'>{item.title}</DropDownTitle>
              <p>{item.InfoContent}</p>
              <ImgWrapper>
                {index === 0 &&
                  <StaticImage src="../../../images/menu/rv-covers.jpg" 
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="rv-covers" />
                }
                {index === 1 &&
                  <StaticImage src="../../../images/menu/vertical-rv.jpg" 
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="vertical-rv" />
                }
                {index === 2 &&
                  <StaticImage src="../../../images/menu/a-frame-rv.jpg" 
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="a-frame-rv" />
                }
                {index === 3 &&
                  <StaticImage src="../../../images/menu/regular-rv.jpg" 
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="regular-rv" />
                }
              </ImgWrapper>
              <BtnTool>
                <Link to={item.link}><PrimaryLinkButton text={item.title} icon={<ArrowNextIcon />} /></Link>
              </BtnTool>
            </RightCol>
          ))}
      </Grid>
    </DropDown>
  )
}

export default RVCoversDropdown
