import React from "react"
import { Link } from 'gatsby'
import {
  DropDown,
  DropDownList,
  DropDownListItem,
} from './dropdown'


function AboutDropdown({ isActive }) {
  return (
    <DropDown className={`dropdown-menu ${isActive ? 'active' : ''}`}>
        <DropDownList>
        {
            [
            {
                title: 'Contact Us',
                link: '/contact-us',
            },
            {
                title: 'About Liberty',
                link: '/about-us',
            },
            {
                title: 'Why Choose Us',
                link: '/why-choose-us',

            }].map((item) => (
                <DropDownListItem key={item}>
                    <Link to={item.link}>{item.title}</Link>
                </DropDownListItem>
            ))}
        </DropDownList>
    </DropDown>
  )
}

export default AboutDropdown