import styled from 'styled-components'
import BreakpointDown from '../../Media/BreakpointDown'
import BreakpointUp from '../../Media/BreakpointUp'

export const DropDown = styled.div`
  cursor: auto;
  left:0;
  right:0;
  box-shadow: 0px 25px 55px rgba(3, 51, 102, 0.3);
  display:none;
  top: 64px;
  ${BreakpointUp.xl`
    top: 94px;
  `}
  ${BreakpointUp.xxl`
    top:114px;
  `}
  &.active{
    display:block;
  }
  .mobile-view{
    display:none;
    ${BreakpointDown.xl`
      display:block;
    `}
  }
  .desktop-view{
    display:block;
    ${BreakpointDown.xl`
      display:none;
    `}
  }
  ${BreakpointUp.xl`
    position:absolute;
  `}
  ${BreakpointDown.xl`
    margin-left:-30px;
    margin-right:-30px;
  `}
  
`
export const Grid = styled.div`
 display:flex;
 /* height:calc(100vh - 114px);
 @media (max-width:1440px){
  height:calc(100vh - 93px);
 } */
 ${BreakpointDown.xl`
  height:auto; 
 `}
`

export const LeftCol = styled.div`
  position:relative;
  flex:0 0 auto;
  width:40%; 
  box-shadow: inset 0px 75px 85px -55px #004B9B33 !important;  
  background:#033366;
  box-shadow: 0px 35px 85px #0000004D;
  padding:30px 60px;
  ${BreakpointUp.xxl`
    padding:60px 90px;
  `}
  ${BreakpointDown.xl`
    flex:0 0 100%;
    background:#F0F8FF;
    padding: 20px 30px 30px;
  `}
  .h3{ color:#fff}
  .btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 28px;
    }
    color:#033366;
    @media (min-width: 1200px) {
    color:#fff;
    }
    @media (min-width: 1600px) {
      font-size: 28px;
      line-height: 36px;
    }
    .icon{
      svg{
        fill:#033366;           
        @media (min-width: 1200px) {
          fill:#fff;    
        }  
      }
    }
    &:hover{
      color:#D51333;           
      @media (min-width: 1200px) {
        color:rgba(240,248,255,0.45);    
      }
      .icon{
          svg{
          fill:#D51333;           
          @media (min-width: 1200px) {
            fill:rgba(240,248,255,0.45);    
          }
        }
      }
    }
  }
`
export const RightCol = styled.div`
  box-shadow: inset 0px 75px 85px -55px #004B9B33 !important;
  background:#FFFFFF;
  flex:1 0 0%;
  padding:30px 60px;
  ${BreakpointUp.xxl`
    padding:60px 90px;
  `}
  ${BreakpointDown.xl`
    width:100%;
  `}
  > p{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
  }
`
export const DropDownTitle = styled.div`
  margin-bottom:15px;
  span{
    display:block;
  }
  ${BreakpointDown.xl`
    display:none;
  `}
`

export const DropDownList = styled.ul`
  list-style:none;
  margin:0;
  padding:0;
`
export const DropDownListItem = styled.li`
  margin-bottom:0;
  ${BreakpointDown.xl`
    display:flex;
    justify-content:space-between;
    align-items:center;
    border-top: 1px solid rgb(154 163 171 / 20%);
    position: relative;
    &:first-child{
      border-top:0;
    }
    .dropdown-item-inner{
      display:flex;
      align-items:center;
      .info-icon{
        margin-left:10px;
        &:hover{
          .info-content{ display:block}
        }
        svg{
          fill:#033366;
          width:28px;
          height:28px;
        }
      }
    }
  `}
  + li{
    a{
      border-top:1px solid rgba(240, 248, 255, 0.20);
    }
  }

  &:first-child{
    a{
      font-family: "Barlow", sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      text-align: left;
      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 28px;
      }
      color:#033366;
      @media (min-width: 1200px) {
      color:#fff;
      }
      @media (min-width: 1600px) {
        font-size: 28px;
        line-height: 36px;
      }
      &:hover{
        color:#D51333;           
        @media (min-width: 1200px) {
          color:rgba(240,248,255,0.45);    
        }
      }
    }
  }

  a{
    display:block;
    font-family: 'Barlow', sans-serif;
    transition: all 0.4s ease-in-out;
    position:relative;
    cursor: pointer;
    color:rgba(3, 51, 102, 0.7);
    padding: 15px 0;
    ${BreakpointUp.xl`
      color:rgba(240, 248, 255, 0.45);
      font-size:18px;
      line-height: 28px;
      font-weight:500;
    `}
    ${BreakpointUp.xxl`
      padding:25px 0;
      font-size:20px;
      line-height: 28px;
      font-weight:600;
      line-height:
    `}
    @media(hover:hover){
      &:hover{
      color:#fff;
        &:after{
          visibility:visible;
          opacity:1;
        }
      }
    }
    &:after{
      visibility:hidden;
      opacity:0;
      content:'';
      position: absolute;
      display: block;
      right: -120px;
      width: 0;
      height: 0;
      border-right: 15px solid #66100300;
      border-bottom: 15px solid #03336600;
      border-left: 15px solid #033366;
      border-top: 15px solid #03664400;
      top: 50%;
      transform: translateY(-50%);
      @media(max-width:1440px){
        right: -90px; 
      }
      ${BreakpointDown.xl`
        display:none;
      `}
    }
  }
`

export const BtnTool = styled.div`  
  padding: 15px 0;
  ${BreakpointUp.xxl`
    padding:25px 0;
  `}
  .btn{
    letter-spacing: 1.12px;
    cursor: pointer;
    
  }
`
export const ImgWrapper = styled.div`
  position:relative;
  max-width:460px;
  width:100%;
  ${BreakpointUp.xxl`
    max-width:560px;
  `}
  &:before{
    content:'';
    width:100%;
    height:100%;
    display:block;
    background:transparent;
    position:absolute;
    z-index:2;
  }
`

export const InfoContent = styled.div`
  position: absolute;
  background: #fff;
  box-shadow: 0px 25px 55px #004b9b26;
  right: 40px;
  z-index: 1;
  top: 0;
  left: 10px;
  padding: 15px;
  font-size: 14px;
  display:none;
  &.active{
    display:block;
  }
  &:before{
    position: absolute;
    top: 9px;
    right: -20px;
    z-index: 1;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid white;
  }
`