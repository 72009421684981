import React, { useState } from "react"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import ArrowDownIcon from '../Icons/ArrowDownIcon'
import PhoneOutlineIcon from '../Icons/PhoneOutlineIcon'
import LineArrowRight from '../Icons/LineArrowRight'
import PrimaryButton from '../Button/PrimaryButton'
import DefaultButton from '../Button/DefaultButton'
import RequestModal from  '../RequestModal'
import BreakpointUp from "../Media/BreakpointUp"
import WelcomeBgImg from "../../images/cta-right-bg.png"


const HowCanHelpLocation = styled.div`
`


const CtaBackground = styled.div`
  width:100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.09;
  .gatsby-image-wrapper{
    height: 100%;
    display:block;
  }
`

const HowCanWeHelpArrow = styled.div`
  background: #d51333;
  color: #fff;
  padding:10px 15px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  cursor: pointer;
  display:flex;
  align-items: center;
  justify-content:center; 
  position: absolute;
  right: 0;
  bottom:calc(100% - 1px);
  z-index:4;
  svg{
    fill:#fff;    
    transform:scaleY(-1);
    transition:all 0.4s ease-in-out;
    position: relative;
    top: 8px;
  }
`

const HowCanWeHelp = styled.div`
  position:fixed;
  left:0;
  right:0;
  z-index:3;
  max-width:100%;
  width:100%;
  transition:bottom 0.4s ease-in-out;
  display:none;  
  align-items: center;
  background-color:#033366;  
  ${BreakpointUp.lg`
    display:flex;
  `}
  bottom: -118px;
  @media (min-width: 1200px) {
  bottom: -124px;
  }
  @media (min-width: 1480px) {
    bottom: -96px;
  }
  &:after, &:before{
    width:12%;
    position: absolute;
    content:"";
    top:0;
    right:0;
    bottom:0;
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);

  }
  &:after{
    background-image:${props => props.bgAfter};
    background-position: top center;
    background-size: cover;
    z-index:1;
    opacity:0.1;
  }
  &:before{    
    background-color:#D51333;
  }
  
  &.show{
    bottom: 0px;  
    .title{
      svg{
        transform:scaleY(1);
      }
    }
  } 
`

const HowCanHelpCarport = styled.div`
  position: relative;
  width: 100%;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  display: none;
  @media (min-width: 1200px) {
    display: block;
  }
  > .gatsby-image-wrapper{
    margin-top:0;
    width: 180px;
    @media (min-width: 1280px) {
      width: 230px;
    }
    @media (min-width: 1480px) {
      margin-top: -40px;
      width: auto;
    }
  }
`
const HowCanWeHelpContent = styled.div`
  position: relative;
  width: 100%;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
`
const HowCanHelpRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin:0 -5px;
  align-items:center;
  justify-content: space-between;
  padding:15px 30px;
  @media (min-width: 1200px) {
    padding:15px 30px 15px 15px;
  }
`

const HowCanHelpContent = styled.div`
  position: relative;
  width: 100%;
  padding:0 5px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  > span, > strong{
    color:#fff;
    display: block;
    font-family: "Barlow", sans-serif;
  }
  > strong{
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.3px;    
    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 30px;
    }
    @media (min-width: 992px) {
      font-size: 28px;
      line-height: 36px;
    }
    @media (min-width: 1600px) {
      font-size: 32px;
      line-height: 42px;
    }
    > span{
      text-transform: uppercase;
      color:#F0AA0F;
    }
  }
  > span{
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 26px;
    }
    @media (min-width: 1600px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  
`
const HowCanHelpBtn = styled.div`
  position: relative;
  width: 100%;
  padding:0 5px;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;  
`
const ButtonToolbar = styled.div`
  position: relative;
  z-index:2;
  display: flex;
  flex-wrap: wrap;
  margin:0 -7px;
  align-items:center;
  justify-content: space-around;
  > a, > button{
    position: relative;
    width: 100%;
    padding:0 7px;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    .btn{
      box-shadow: 0 15px 50px rgba(0,0,0,0.5);
      border:1px solid rgba(255,255,255,0.4);
      @media (min-width: 1600px) {
        padding: 14px 24px;        
      }
      @media (min-width: 1680px) {
        min-width: 294px;
        max-width:294px;
        width:100%;
      }
    }
  }  
  > a{
    .btn{
      font-size:18px;
      @media (min-width: 1600px) {        
        font-size:20px;
      }
      > .icon + .text{
        margin-left:5px;
      }
    }
  }
`

const HelpFn = ({ location }) => {
  const [showHelp, setShowHelp] = useState(false) 
  const [isQuoteVisible, setIsQuoteVisible] = useState(false)

  const ToggleHelp = () => {
    setShowHelp(!showHelp);
  }
  const HandleModalOpen = () => {
    setIsQuoteVisible(true)
    document.querySelector("body").classList.add("modal-open")
  }
  const HandleModalClose = () => {
    setIsQuoteVisible(false)
    document.querySelector("body").classList.remove("modal-open")
  }
  
  return (
    <HowCanHelpLocation location={location}>
    <HowCanWeHelp className={showHelp ? '' : 'show'} bgAfter={`url(${WelcomeBgImg})`}  >
      <HowCanWeHelpArrow className="title" onClick={ToggleHelp}><ArrowDownIcon /></HowCanWeHelpArrow>
      <CtaBackground>
        <StaticImage
          src="../../images/cta-bg.png"
          placeholder="blurred"
          alt="cta-bg"
        />
      </CtaBackground>
      <HowCanHelpCarport>
        <StaticImage
          src="../../images/metal-building-arrow.png"
          placeholder="blurred"
          alt="metal-building-arrow"
        />
      </HowCanHelpCarport>
      <HowCanWeHelpContent>
        <HowCanHelpRow>
          <HowCanHelpContent>
            <strong>Order your <span>Metal Building</span> Today!</strong>
            <span>Talk to us now and get the best prices on Steel Buildings. RTO and Financing Available!</span>
          </HowCanHelpContent>
          <HowCanHelpBtn>
            <ButtonToolbar>
              <a href="tel:8004407309" aria-label="Phone"><PrimaryButton textAfter="(800) 440-7309"  icon={<PhoneOutlineIcon />} /></a>
              <button role="button" aria-label="Get Your Free Quote" type="button" onClick={HandleModalOpen}><DefaultButton icon={<LineArrowRight />} text="Get Your Free Quote" /></button>
            </ButtonToolbar>
          </HowCanHelpBtn>
        </HowCanHelpRow>
      </HowCanWeHelpContent>
    </HowCanWeHelp>
    <RequestModal location={location} isVisible={isQuoteVisible} onClose={HandleModalClose} />
    </HowCanHelpLocation>
  )
}
export default HelpFn; 